
import Vue from "vue"
import { Route } from "vue-router"
import { UnionItemDefinition } from "@/types/responses"
import { NavSubItems } from "@/types/enums"
import { find } from "lodash"
export default Vue.extend({
	name: "GenericView",
	watch: {
		$route(to: Route, from: Route) {
			console.error(to, from)
		},
	},
    data(): {
        header: string | null
    } { return {
            header: ""
        }
    },

	computed: {
		subItems(): UnionItemDefinition["subItems"] {
            const currentPath = this.$route.fullPath
            //console.log("Current Path: ", currentPath)
			const modules: UnionItemDefinition[] = this.$vStore.state.nav.modules ? this.$vStore.state.nav.modules["items"] : []
			const subItemsModule = modules.find((module) => {

				if(module.subItems.length > 0){

                    if(module.route === currentPath){
                        this.header = module.name
                        return module
                    }

					const subItems = module.subItems

				}
				

			})
			return subItemsModule ? subItemsModule["subItems"] : []
		},
	},
	methods: {
		bgImage(bgImage: string): void {
			return require(`@/assets/bg/${bgImage}`)
		},
	},
})
